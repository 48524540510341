import React, { useState } from 'react';
// import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Amplify from 'aws-amplify';

import Layout from '../layouts/Layout';
import Step from '../sections/Step';
import config from '../../aws-exports';
import variables from '../../styles/variables';

Amplify.configure(config);

const Recipe = ({ pageContext: { recipe } }) => {
  const [showingIngredient, setShowingIngredient] = useState(null);
  const ingredients = Array.from(
    new Set(recipe.steps.flatMap(({ tasks }) => tasks.flatMap(task => task.ingredients))),
  );
  const allTasks = recipe.steps.flatMap(({ id, tasks }) =>
    tasks.flatMap((task, index) => ({ ...task, stepId: id, taskIndex: index })),
  );
  return (
    <Layout>
      <Container>
        <h1>{recipe.name}</h1>
        {ingredients.map(ingredient => (
          <button type="button" key={ingredient} onClick={() => setShowingIngredient(ingredient)}>
            {ingredient}
          </button>
        ))}
        {showingIngredient && (
          <IngredientHistory>
            {allTasks
              // eslint-disable-next-line no-shadow
              .filter(({ ingredients }) => ingredients.includes(showingIngredient))
              .map(task => (
                <div>
                  Step {task.stepId}: {task.taskText}
                </div>
              ))}
            <button type="button" onClick={() => setShowingIngredient(null)}>
              Done
            </button>
          </IngredientHistory>
        )}

        {recipe.steps.map(step => (
          <Step key={step.id} step={step} />
        ))}
      </Container>
    </Layout>
  );
};

Recipe.propTypes = {
  recipe: PropTypes.object.isRequired,
}.isRequired;

Recipe.defaultProps = {};

const Container = styled.div`
  background-color: #eee;
  border-radius: ${variables.borderRadius * 2}px;
  padding: 40px;
  margin-bottom: 40px;
  position: relative;
`;

const IngredientHistory = styled.div``;

export default Recipe;
