import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import variables from '../../../styles/variables';
import { logOut, isLoggedIn, getCurrentUser } from '../../../utils/auth';
import logo from '../../../images/logo.png';

const Header = ({ siteTitle }) => {
  const user = getCurrentUser();
  return (
    <Container>
      <Wrapper>
        <Link to="/">
          <Image src={logo} alt="logo" />
        </Link>
        <Title to="/">{siteTitle}</Title>
        {isLoggedIn() ? (
          <NavLink
            onClick={async () => {
              const { error } = await logOut();
              if (!error) {
                navigate('/app/login');
              }
            }}
          >
            Logout ({user && user.username ? user.username : null})
          </NavLink>
        ) : (
          <SignInLink to="/app/login">Login</SignInLink>
        )}
      </Wrapper>
    </Container>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

const Container = styled.div`
  background: ${variables.primaryColor};
  flex-direction: row;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${variables.defaultWidth}px;
  padding: 1.45rem 1.0875rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const Title = styled(Link)`
  display: inline-block;
  margin: 0;
  color: white;
  text-decoration: none;
  font-size: 32px;
  font-family: sans-serif;
  font-weight: bold;
`;

const Image = styled.img`
  margin: 0;
`;

const NavLink = styled.p`
  margin: 0;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

const SignInLink = styled(Link)`
  margin: 0;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

export default Header;
