import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ step }) => {
  return (
    <div id={step.id}>
      <h1>{step.name}</h1>
      <ul>
        {step.tasks.map(({ taskText }) => (
          <li key={taskText}>{taskText}</li>
        ))}
      </ul>
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    tasks: PropTypes.array,
  }).isRequired,
};

export default Step;
