export default {
  // layout
  gridStep: 8,
  defaultWidth: 960,
  mobileWidth: 640,
  borderRadius: 8,
  // fonts
  defaultFontSize: 16,
  mobileFontSize: 14,
  defaultFontWeight: 400,
  // colors
  primaryColor: '#ccc',
  secondaryColor: '#444',
  defaultBackground: '#fff',
};
