import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import variables from '../../../styles/variables';
import { isLoggedIn } from '../../../utils/auth';

const Navigation = () => (
  <Container>
    <Wrapper>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      {isLoggedIn() ? (
        <AuthenticatedNavigation>
          <Link to="/app/profile">Profile</Link>
          <Link to="/app/home">User Home</Link>
        </AuthenticatedNavigation>
      ) : null}
    </Wrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  background-color: ${variables.secondaryColor};
  margin-bottom: 24px;
`;
const Wrapper = styled.div`
  max-width: ${variables.defaultWidth}px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  a {
    color: white;
    padding: 24px;
  }
`;

const AuthenticatedNavigation = styled.div`
  position: absolute;
  right: 0;
  display: flex;
`;

export default Navigation;
